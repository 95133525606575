// import Vue from 'vue';

const LoadingOverlayPlugin = {
    install(Vue) {
        const loadingOverlay = {
            show() {
                const overlay = document.createElement('div');
                overlay.className = 'loading-overlay';
                overlay.innerHTML = `
          <div class="loading-spinner">
            <svg viewBox="25 25 50 50" class="spinner">
              <circle cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
            </svg>
          </div>
        `;
                document.body.appendChild(overlay);

                this.hide = () => {
                    const overlay = document.querySelector('.loading-overlay');
                    if (overlay) {
                        overlay.remove();
                    }
                };

                return this;
            },
            // hide() {
            //     const overlay = document.querySelector('.loading-overlay');
            //     if (overlay) {
            //         overlay.remove();
            //     }
            // },
        };

        Vue.prototype.$loading = loadingOverlay;
    },
    version: '0.0.2',
};

export default LoadingOverlayPlugin;
